<template>
<v-row no-gutters>
      <v-col cols="5" md="2" class="ml-3 mb-2 mt-2">
          <v-menu
            v-model="fromMenu"
            full-width
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fromDate"
                label="From Date"
                readonly
                v-on="on"
                hide-details
                dense
                filled
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromPicker"
              @input="fromMenu = false"
              color="primary"
              :max="new Date().toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="5" md="2" class="ml-3 mb-2 mt-2">
          <v-menu
            v-model="toMenu"
            full-width
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="toDate"
                label="To Date"
                readonly
                v-on="on"
                hide-details
                dense
                filled
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toPicker"
              @input="toMenu = false"
              color="primary"
              :max="new Date().toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
          </v-col>
          <v-col cols="4" md="2"  class="ml-3 mb-2 mt-2">
                  <v-autocomplete
                    :rules="[(v) => !!v || 'required']"
                    v-model="status"
                    item-text="name"
                    :items="statusList"
                    label="Status"
                    name="name"
                    return-object
                    required
                    dense
                    outlined
                    filled
                  >
                </v-autocomplete>
              </v-col>
          <v-col cols="1" md="1" class="pl-2 pt-3">
          <v-btn icon class="primary" @click="invoiceListMethod()" small>
            <v-icon color="white"> search </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="12" class="pl-2 pr-2">
          <v-simple-table
           :fixed-header="true"
           class="elevation-1 align-center"
           height="83vh"
           dense
         >
           <template v-slot:default>
             <thead>
               <tr>
                 <th class="text-center white--text primary">No.</th>
                 <th class="text-center white--text primary">
                   <v-divider vertical></v-divider>
                 </th>
                 <th class="text-center white--text primary">ReceivedDate</th>
                 <th class="text-center white--text primary">
                   <v-divider vertical></v-divider>
                 </th>
                 <th class="text-center white--text primary">invoiceNo</th>
                 <th class="text-center white--text primary">
                   <v-divider vertical></v-divider>
                 </th>
                 <th class="text-center white--text primary">Customer Name</th>
                 <th class="text-center white--text primary">
                   <v-divider vertical></v-divider>
                 </th>
                 <th class="text-center white--text primary">Sign</th>
                 <th class="text-center white--text primary">
                   <v-divider vertical></v-divider>
                 </th>
                 <th class="text-center white--text primary">Total Amt</th>
                 <th class="text-center white--text primary">
                   <v-divider vertical></v-divider>
                 </th>
                 <th class="text-center white--text primary"> Pre-paid</th>
                 <th class="text-center white--text primary">
                   <v-divider vertical></v-divider>
                 </th>
                 <th class="text-center white--text primary"> Payment</th>
                 <th class="text-center white--text primary">
                   <v-divider vertical></v-divider>
                 </th>
                 <th class="text-center white--text primary">Balance</th>
                 <th class="text-center white--text primary">
                   <v-divider vertical></v-divider>
                 </th>
                 <th class="text-center white--text primary">Remark</th>
                 <th class="text-center white--text primary">
                   <v-divider vertical></v-divider>
                 </th>
                 <th class="text-center white--text primary">Action</th>
               </tr>
             </thead>
             <tbody>
               <tr
                 v-for="(item, index) in invoiceList"
                 :key="index"
                 @click="selectedOne = item"
                 :style="{
                   backgroundColor:
                     item.invoiceId == selectedOne.invoiceId
                       ? '#def3ff'
                       : 'transparent',
                 }"
               >
                 <td class="text-center">{{ index + 1 }}</td>
                 <td class="text-center"><v-divider vertical></v-divider></td>
                 <td class="text-center">{{ item.receivedDate }}</td>
                 <td class="text-center"><v-divider vertical></v-divider></td>
                 <td class="text-center">{{ item.invoiceCode }}</td>
                 <td class="text-center"><v-divider vertical></v-divider></td>
                 <td>{{ item.customer.customerName }}</td>
                 <td class="text-center"><v-divider vertical></v-divider></td>
                 <td class="text-right" >{{ item.exchange.symbols }}</td>
                 <td class="text-center"><v-divider vertical></v-divider></td>
                 <td class="text-right" >{{ item.totalAmount|numberFormat }}</td>
                 <td class="text-center"><v-divider vertical></v-divider></td>
                 <td class="text-right" >{{ item.advancePaid|numberFormat }}</td>                 
                 <td class="text-center"><v-divider vertical></v-divider></td>
                 <td class="text-right" >{{ (item.otherPayment-item.advancePaid)|numberFormat }}</td>
                 <td class="text-center"><v-divider vertical></v-divider></td>
                 <td class="text-right" >{{ item.balance|numberFormat }}</td>
                 <td class="text-center"><v-divider vertical></v-divider></td>
                 <td class="text-right" >{{ item.remark }}</td>
                 <td class="text-center"><v-divider vertical></v-divider></td>
                 <td class="text-center">
                  <v-btn small icon color="blue" @click="clickDetailDialog(item)">
                     <v-icon>info</v-icon></v-btn
                   >
                  <v-btn small icon color="black" @click="clickPrint(item)" class="ml-2">
                     <v-icon>print</v-icon></v-btn
                   >
                   <v-btn small icon color="green" @click="clickEdit(item)" class="ml-2">
                     <v-icon>edit</v-icon></v-btn
                   >
                   <v-btn small icon color="red" @click="clickDelete(item) " class="ml-2">
                     <v-icon>delete</v-icon></v-btn
                   >
                 </td>
               </tr>
               <v-divider />
             </tbody>
           </template>
         </v-simple-table>
        </v-col>
        <v-col>
      <v-dialog
      v-model="dialogDelete"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 white--text red">
          Delete
        </v-card-title>

        <v-card-text class="text-h6 ">
           Are you sure to delete({{deleteInvoice.invoiceId}})?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogDelete = false"
          >
            CANCEL
          </v-btn>
          <v-btn
          dark
            class="red"
            text
            @click="clickDeleteDialog()"
          >
            DELETE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-col>
    <v-col>
          <v-bottom-sheet v-model="dialogDetail" fullscreen scrollable>
        <v-sheet>
          <InvoiceDetail
            @closeDialog="dialogDetail=false"
            :invDtl="invDtl"
          />
        </v-sheet>
      </v-bottom-sheet>
        </v-col>
        </v-row>
</template>
<script>
import invoiceService from "../service/InvoiceService";
import InvoiceDetail from "@/components/InvoiceDetail.vue";
export default {
  data: () => ({
    invDtl:{},
    dialogDetail:false,
    fromPicker: new Date().toISOString().substr(0, 10),
    toPicker: new Date().toISOString().substr(0, 10),
    fromMenu: false,
    toMenu: false,
    fromDate: "",
    toDate: "",
    invoiceList:[],
    selectedOne:{},
    dialogDelete:false,
    deleteInvoice:{},
    statusList: ["ACTIVE", "INACTIVE"],
    status: "",
  }),
   props: {},
  mounted: function() {
    this.fromDate = this.formatDate(this.fromPicker);
    this.toDate = this.formatDate(this.toPicker);
    this.invoiceListMethod();
    this.status = this.statusList[0];
  },
  methods: {
    clickDetailDialog:function(item){
          this.invDtl = item;
          this.dialogDetail = true;      
        },  
    clickPrint: function(invoiceDto) {
      let invoiceId = invoiceDto.invoiceId;
          let query = {invoiceId};
            this.$router.push({
              name: "invPrint",
              query,
            }) .catch(() => {});
      },
    clickDeleteDialog:function(){
      this.dialogDelete = false;
      invoiceService
          .deleteInvoice(this.deleteInvoice.invoiceId)
          .then((response) => {
            this.invoiceListMethod();
            this.$swal({
                title: "Successful",
                text: "Save,Success!",
                type: "success",
                timer: 500,
              });
          })
          .catch((error) => {
            this.$swal("Fail!", error.response.data.message, "error");
          });
    },
    clickDelete:function(item){
        this.deleteInvoice = Object.assign({},item);
        this.dialogDelete = true;
    },
    clickEdit:function(item){
      let invoiceId = item.invoiceId;
          let query = {invoiceId};
            this.$router.push({
              name: "invoice",
              query,
            });
    },
    formatDate(datePicker) {
      const [year, month, day] = datePicker.split("-");
      return `${day}-${month}-${year}`;
    },
    invoiceListMethod:function(){
      invoiceService
          .getInvoice(this.fromDate,this.toDate,this.status,'INVOICE')
          .then((response) => {
            this.invoiceList.splice(0);
            this.invoiceList.push(...response);
          })
          .catch((error) => {
            this.$swal("Fail!", error.response.data.message, "error");
          });
    },
  },
  watch: {
    fromPicker() {
      this.fromDate = this.formatDate(this.fromPicker);
    },
    toPicker() {
      this.toDate = this.formatDate(this.toPicker);
    },
  },
  components: {InvoiceDetail}
};
</script>
<style scoped>
.v-data-table td,
.v-data-table th {
  padding: 0 2px !important;
}
.login-full {
  height: 99vh;
}
</style>